import * as React from 'react';
import {
  createInvite,
  deleteInvite,
  getInviteByEmail,
  getMyInvites,
} from '../../data/services/Invites/InviteService';
import { Invite } from '../../domain/types/Invite';
import { Button, Modal, Tooltip } from 'antd';
// import { create } from 'domain';
import useProfileStore from '../../data/appState/profileStore';
import toast from '../common/Toast';
import { convertTime } from '../../data/services/explore/converters';
import { copyToClipboard } from '../common/utils';
import { getUserProfileByEmail } from '../../data/services/profile/profileService';

export interface IUserInvitesProps {}

export function UserInvites(props: IUserInvitesProps) {
  const [invites, setInvites] = React.useState<Invite[]>([]);

  const [email, setEmail] = React.useState<string>('');

  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);

  const [selectedInvite, setSelectedInvite] = React.useState<Invite | null>(
    null,
  );

  const { profile } = useProfileStore();

  const loadInvites = React.useCallback(() => {
    if (profile && profile.uid)
      getMyInvites(profile?.uid).then((invites) => {
        invites = invites
          .map((invite) => {
            const { updatedOn } = invite as any;
            if (updatedOn?.toDate()) invite.updatedOn = updatedOn?.toDate();
            return invite;
          })
          .sort((a, b) => {
            return b.updatedOn.getTime() - a.updatedOn.getTime();
          });

        setInvites(invites);
      });
    // getInvites().then((invites) => {
    // invites = invites
    //   .map((invite) => {
    //     const { updatedOn } = invite as any;
    //     if (updatedOn?.toDate()) invite.updatedOn = updatedOn?.toDate();
    //     return invite;
    //   })
    //   .sort((a, b) => {
    //     return b.updatedOn.getTime() - a.updatedOn.getTime();
    //   });

    // setInvites(invites);
    // });
  }, [profile]);

  React.useEffect(() => {
    loadInvites();
  }, [loadInvites]);

  function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const handleDelete = async () => {
    if (selectedInvite?.db_ref_id)
      try {
        await deleteInvite(selectedInvite?.db_ref_id);
        toast.success(
          'Invite deleted',
          'The invite has been deleted successfully',
        );
        loadInvites();
        setIsModalVisible(false);
      } catch (error) {
        toast.error('Error', 'An error occured while deleting the invite');
      }
  };

  const sendInvite = async () => {
    try {
      console.log('Send invite');

      const { orgType } = profile ?? {};

      // validate email
      if (!email || validateEmail(email) === false) {
        toast.error('Invalid email', 'Please enter a valid email address');
        return;
      }

      let res = await getInviteByEmail(email);
      // let res = await getUserProfileByEmail(email);
      if (res && res.length !== 0) {
        toast.error(
          `Email ${email} already exists.`,
          'Please try with another email',
        );
        return;
      }

      let result = await getUserProfileByEmail(email);
      debugger;
      if (!!result) {
        toast.error(
          `Email ${email} already exists.`,
          'Please try with another email',
        );
        return;
      }

      //  if (invites.findIndex((invite) => invite.createToEmail === email) > -1) {
      //    toast.error(
      //     'Invite already sent',
      //     'The invite has already been sent to this email address',
      //   );
      //   return;
      // }

      const invite = {
        createToEmail: email,
        status: 'pending',
        createdOn: new Date(),
        updatedOn: new Date(),
        createdById: profile?.uid,
        createToOrgType: orgType ? orgType : '',

        createdByName: profile?.displayName ?? '',
        createdByEmail: profile?.email ?? '',
        createdByOrgId: profile?.organisationId ?? '',
        createdByOrgName: profile?.organisationName ?? '',
        createdByOrgType: profile?.orgType,
      } as Invite;

      createInvite(invite).then((invite) => {
        toast.success('Invite sent', 'The invite has been sent successfully');
        setEmail('');
        loadInvites();
      });
    } catch (error) {
      toast.error('Error', 'An error occured while sending the invite');

      console.log('Error in sendInvite', error);
    }
  };

  return (
    <div className="body_layout">
      <div className="user_invite">
        <div className="invite_form">
          <div className="title_wrapper">
            <h2>User Invites</h2>
          </div>
          <div className="form_wrapper">
            <h2> Send invite </h2>
            <div className="form_input">
              <input
                type="text"
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                placeholder="Email"
              />
              <Button type="primary" onClick={sendInvite}>
                Send
              </Button>
            </div>
          </div>
        </div>
      </div>

      {invites && invites.length !== 0 ? (
        <div className="table-container slim_scrollbar invite_table ">
          <table>
            <thead>
              <tr>
                <th>Email</th>
                <th>Invited On</th>
                <th>STATUS</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {invites.map((invite) => {
                return (
                  <tr key={invite.db_ref_id}>
                    <td>{invite.createToEmail}</td>
                    <td>{convertTime(invite?.updatedOn)}</td>
                    <td>
                      {invite.status !== 'accepted' ? (
                        invite.status
                      ) : (
                        <div style={{ color: 'green' }}>
                          <Tooltip
                            placement="bottomLeft"
                            title={
                              <span style={{ color: 'orange' }}>
                                {'Accepted By ' +
                                  invite.acceptedByName +
                                  '(' +
                                  invite.acceptedByEmail +
                                  ') for the organisation ' +
                                  invite.acceptedByOrgName}
                              </span>
                            }
                            arrow={true}
                          >
                            <p style={{ color: 'green' }}>Accepted</p>
                          </Tooltip>
                        </div>
                      )}
                    </td>

                    <td>
                      {' '}
                      <Button
                        type="primary"
                        onClick={() => {
                          copyToClipboard(
                            window.location.origin +
                              '/invitee/signup/' +
                              invite.db_ref_id,
                          );
                        }}
                      >
                        Copy Link
                      </Button>{' '}
                      <Button
                        style={{ marginLeft: '.75rem' }}
                        onClick={() => {
                          setSelectedInvite(invite);
                          setIsModalVisible(true);
                        }}
                      >
                        Delete
                      </Button>{' '}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <p style={{ textAlign: 'center' }}>
          {' '}
          <b>No invitees</b>
        </p>
      )}

      <Modal
        title="Please confirm"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={
          <div className="modal_footer">
            <Button
              type="default"
              onClick={() => {
                setIsModalVisible(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => {
                handleDelete();
              }}
            >
              Delete
            </Button>
          </div>
        }
      >
        <div>
          <p>Are you sure you want to delete this invite?</p>
        </div>
      </Modal>
    </div>
  );
}
