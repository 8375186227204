import toast from './Toast';

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [
    'Bytes',
    'KiB',
    'MiB',
    'GiB',
    'TiB',
    'PiB',
    'EiB',
    'ZiB',
    'YiB',
  ];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const replaceWithBr = (value) => {
  if (value) return value.replace(/\n/g, '<br />');
};

type DDLItem = { value: string; label: string };

export const getFileInfoByURL = (
  url: string,
  isFireBaseUrl: boolean = true,
) => {
  url = decodeURIComponent(url);
  let filePath = url.split('?')[0],
    fileExt = filePath.split('.').pop(),
    fileName = filePath.split('/').pop()?.split('--').pop(),
    fileStorePath = filePath.split('/').splice(-2).join('/');
  return {
    filePath,
    fileExt,
    fileName,
    fileStorePath,
  };
};

export const convertToDDLData = ({
  data,
  labelField,
  valueField,
  addNone = false,
}: {
  data: object[];
  labelField: string;
  valueField: string;
  addNone?: boolean;
}): DDLItem[] => {
  let res = data.map((obj) => {
    return { label: obj[labelField], value: obj[valueField] };
  });
  return !addNone ? res : [{ label: 'None', value: null }, ...res];
};

export const cleanObject = (obj) => {
  let newObj = {};

  for (const key in obj) {
    if (
      Object.prototype.hasOwnProperty.call(obj, key) &&
      obj[key] !== null &&
      obj[key] !== undefined
    ) {
      newObj[key] = obj[key];
    }
  }
  return newObj;
};

export const convertNumbertoCurrency = (num?: number | string): string => {
  return num
    ? '$' + String(num)?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    : '';
};

export const getUrlParameterValue = (parameter: string): string => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(parameter) ?? '';
};

export const getStrings = (selArr, arr, id, value) => {
  if (selArr && selArr?.length !== 0 && arr && arr.length !== 0) {
    let selInds = getSelStrings(selArr, arr, id, value);
    return selInds;
  }
};

export const getSelStrings = (
  selectedArr: string[],
  arr,
  id,
  value,
): string[] => {
  if (selectedArr && selectedArr?.length !== 0 && arr && arr.length !== 0) {
    return arr
      .filter((obj) => selectedArr?.indexOf(obj[id]) !== -1)
      .map((ind) => {
        return ind[value];
      });
  } // returns value

  return [];
};

export const copyToClipboard = (value) => {
  navigator.clipboard.writeText(value).then(
    () => {
      // openNotification(`Copied to clipboard`, notificationTypes.success);
      toast.success('Copied to clipboard', '');
    },
    () => {
      console.error('Failed to copy');
      // openNotification(`Failed to copy `, notificationTypes.error);
      toast.error('Failed to copy ', '');
    },
  );
};
