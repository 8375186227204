import * as React from 'react';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate, useLocation, Link } from 'react-router-dom';
import { FOOTER_LOGO, WVP_LOGO_NEW } from '../common/asset_paths';
import { getMenuLinkText } from '../common/constants';

import { UnorderedListOutlined, UserOutlined } from '@ant-design/icons';
import { Popover, Badge, Avatar, Space, Dropdown, Button } from 'antd';
import useProfileStore from '../../data/appState/profileStore';
import { OrgType, Profile } from '../../domain/types/Profile';
import { signOutUser } from '../../data/services/auth/authService';
import { useLoadSiteData } from '../../data/services/common/siteDataService';
import { onMyConversationsUpdate } from '../../data/services/conversation/conversationService';
import {
  getProfileConversationsByProfileId,
  updateNotificationViewedOn,
} from '../../data/services/profile/profileService';
import NotificationsList from '../components/NotificationList';
import {
  getNotificationsByUserId,
  updateNotificationsReadStatusById,
} from '../../data/services/notifications/notificationService';
import { AppNotification } from '../../domain/types/Notification';
import {
  AccountIcon,
  ArrowMenu,
  ExploreIcon,
  InstagramIcon,
  MenuBellIcon,
  MessagesIcon,
  NetworkIcon,
  SettingIcon,
  SignoutIcon,
  UserIcon,
  XIcon,
} from '../common/utilComponents';
import { DownloadPrompt } from '../components/DownloadPrompt';
import {
  saveFCMTokenToUser,
  UpdateUserPushMessageToken,
} from '../../../api/features/profile';
declare global {
  interface Window {
    MSStream?: any;
  }
  interface Window {
    opera?: any;
  }
  interface Window {
    gtag?: any;
  }
}

export interface IPortalLayoutProps {}

export function PortalLayout(props: IPortalLayoutProps) {
  const {
    isAuthenticated,
    profile,
    orgConnections,
    unReadConvoIds,
    setOrgConnections,
    setUnReadConvoIds,
  } = useProfileStore();

  const location = useLocation();
  const navigateTo = useNavigate();

  const lsIsWebView = localStorage.getItem('iswebview');

  const isWebView = lsIsWebView?.toString() === 'true';

  const [activemenu, setActivemenu] = useState('explore');
  const [convoState, setConvoState] = useState<any>(null);
  const [mobileHeaderTitle, setMobileHeaderTitle] = useState('');

  const [showNotification, setShowNotification] = useState(false);
  const [notifications, setNotifications] = useState<AppNotification[]>([]);
  const [open, setOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const bottomNavHidden = /^\/chat\/[a-zA-Z0-9]+$/.test(location.pathname);

  let profileOrgType = profile?.orgType as OrgType;
  let isAdmin = profileOrgType === 'ADMIN';

  //const [isNotified, setIsNotified] = useState(false);

  const hide = () => {
    setOpen(false);
  };

  const onNotificationClick = async (isNotificationsVisible) => {
    setOpen(isNotificationsVisible);
    // setIsNotified(true);
    if (isNotificationsVisible && !profile?.db_ref_id) return;
    try {
      await updateNotificationViewedOn(profile?.db_ref_id!!);

      let unReadNotifs = notifications.filter((n) => n.status === 'UNREAD');

      if (unReadNotifs.length > 0 && profile?.uid) {
        await updateNotificationsReadStatusById(
          profile?.uid,
          unReadNotifs.map((n) => n.db_ref_id),
        );

        setTimeout(() => {
          setNotifications(
            notifications.map((n) => {
              n.status = 'READ';
              return n;
            }),
          );
        }, 1800);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loadNotifications = React.useCallback(async () => {
    let profileId = profile?.uid;

    if (profileId)
      getNotificationsByUserId(profileId).then((res) => {
        if (res.length > 0) {
          res = res
            .map((notification: any) => {
              notification.timestamp = notification.timestamp
                ? notification.timestamp.toDate()
                : new Date();

              return notification as AppNotification;
            })
            .sort((a: AppNotification, b: AppNotification) => {
              return !b.timestamp || !a.timestamp
                ? -1
                : b.timestamp.valueOf() - a.timestamp.valueOf();
            });

          setNotifications(res);
        }
      });
  }, [profile?.uid]);

  useEffect(() => {
    setMobileHeaderTitle(getMenuLinkText(location.pathname));
    window.scrollTo(0, 0);
  }, [location]);

  const handleLinkClick = (path) => {
    // setActiveLink(path);
  };

  const { loaded } = useLoadSiteData();

  useEffect(() => {
    if (isAuthenticated) {
      if (!profile) {
        navigateTo('/login');
      } else {
        loadNotifications();

        if (profile?.organisationId) {
          onMyConversationsUpdate(profile, (res) => {
            console.log('convo update .!!!!');
            setOrgConnections(res);
          });
        }

        if (window.gtag && profile?.uid) {
          window.gtag('js', new Date());
          window.gtag('config', 'G-90VEK60GXP', { user_id: profile.uid });
        }
      }
    }
  }, [
    isAuthenticated,
    profile,
    loadNotifications,
    navigateTo,
    setOrgConnections,
  ]);

  const setFCMToken = async (uid: string) => {
    let localStorageToken = window['fcmtokem'];

    if (localStorageToken) {
      //alert(localStorageToken);
      await saveFCMTokenToUser(uid, localStorageToken);
    } else {
      try {
        await UpdateUserPushMessageToken(uid);
      } catch (error) {}
    }
  };

  useEffect(() => {
    if (profile?.db_ref_id) {
      try {
        getProfileConversationsByProfileId(profile?.db_ref_id).then((s) => {
          setConvoState(s);
        });
      } catch (error) {
        console.warn(error);
      }
    }

    if (profile?.uid) {
      setFCMToken(profile?.uid);
    }
  }, [profile?.db_ref_id, profile?.uid]);

  useEffect(() => {
    let unreadConvos: string[] = [];

    if (orgConnections?.length) {
      for (const connection of orgConnections) {
        const { lastMessage } = connection;
        if (lastMessage?.createdById !== profile?.uid) {
          let thisconvoState: any = null;
          if (convoState?.find) {
            thisconvoState = convoState?.find(
              (c) => c.db_ref_id === connection.db_ref_id,
            );
          }

          if (
            lastMessage &&
            (!thisconvoState ||
              (thisconvoState &&
                thisconvoState.lastVisited < lastMessage?.createdOn))
          ) {
            console.log('unreadConvo', connection);
            unreadConvos.push(connection.db_ref_id);
          }
        }
      }
    }

    // if (unReadConvoIds < unreadConvos && unreadConvos.length > 0) {
    // const messageCount = unreadConvos.length;
    // const messageText = messageCount === 1 ? 'new message' : 'new messages';
    // toast.info(`You have ${messageCount} ${messageText}`, '');
    // }
    //to do reenable
    setUnReadConvoIds(unreadConvos);
  }, [convoState, orgConnections, profile?.uid, setUnReadConvoIds]);

  const { displayName, email, orgType, photoURL } = profile || ({} as Profile);

  const navigateToOrg = () => {
    // if (profile?.role === 'Member') {
    //   let path = `/${orgType?.toLowerCase()}/${profile.organisationId}/view`;
    //   navigateTo(path);
    //   // setActiveLink(path);
    // } else {
    navigateTo(`${orgType?.toLowerCase()}`);
    setOpenDropdown(false);
    // setActiveLink(`${orgType?.toLowerCase()}`);
    // }
  };

  const profileIconMenu = (
    <div className="profile-card">
      <div
        onClick={(e) => {
          navigateTo('profile');
          setOpenDropdown(false);
        }}
      >
        <div className="profile-header" key={'profile'}>
          {!photoURL ? (
            <Avatar icon={<UserOutlined />} className="navbar-img" />
          ) : (
            <Avatar
              src={<img src={photoURL} alt="avatar" />}
              className="profile-picture"
            />
          )}
          <div className="profile-info">
            <h2> {displayName}</h2>
            <p>{email}</p>
          </div>
        </div>
      </div>
      <hr></hr>
      <div className="profile-menu">
        <div className="menu-item" key={'orgnization'} onClick={navigateToOrg}>
          <div>
            <UserIcon className="icon" />
            <span>My {orgType?.toLowerCase()} profile</span>
          </div>
        </div>
        {!isAdmin && profile?.orgType?.toLowerCase() === 'investor' ? (
          <div
            className="menu-item"
            key={'investor'}
            onClick={(e) => {
              navigateTo('investor-invites');
              setOpenDropdown(false);
            }}
          >
            <div>
              <UnorderedListOutlined className="icon" />
              <span>Invite investors</span>
            </div>
          </div>
        ) : (
          <></>
        )}
        {!isAdmin && profile?.role === 'Member' ? (
          <></>
        ) : (
          <div
            className="menu-item"
            key={'settings'}
            onClick={(e) => {
              navigateTo('settings');
              setOpenDropdown(false);
            }}
          >
            <div>
              <SettingIcon className="icon" />
              <span>Settings</span>
            </div>
          </div>
        )}
        {!isAdmin && profile?.orgType?.toLowerCase() === 'realestate' ? (
          <div
            className="menu-item"
            key={'REALESTATE'}
            onClick={(e) => {
              navigateTo('opportunities');
              setOpenDropdown(false);
            }}
          >
            <div>
              <UnorderedListOutlined className="icon" />
              <span>My Listings</span>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="menu-item" key={'logout'}>
          <div onClick={signOutUser}>
            <SignoutIcon className="icon" />
            <span>Sign Out</span>
          </div>
        </div>
      </div>
    </div>
  );

  const notificationcontent = () => {
    return (
      <>
        <div className="nav_list_notifications">
          <NotificationsList
            maxNotifications={4}
            notifications={notifications}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'right',
            paddingTop: '2rem',
          }}
        >
          {
            <Button
              type="default"
              onClick={() => {
                hide();
                setShowNotification(true);
              }}
            >
              See all
            </Button>
          }
        </div>
      </>
    );
  };

  const unreadNotificationCount = notifications.filter(
    (n) => n.status === 'UNREAD',
  ).length;

  return (
    <div className="portallayout">
      <div className="navbar">
        <nav className="navbar-header">
          <div className="navbar-container">
            <div className="mobile_header_title">
              <h1>{mobileHeaderTitle}</h1>
            </div>
            <Link to={'/explore'} className="navbar-logo">
              <img src={WVP_LOGO_NEW} alt="Logo" />
            </Link>

            <ul className="navbar-menu">
              {!isAdmin ? (
                <></>
              ) : (
                <li className="navbar-item">
                  <Link
                    className={`navbar-link ${location.pathname.indexOf('admin') !== -1 ? 'active' : ''}`}
                    to={'/admin'}
                    onClick={() => handleLinkClick('/admin')}
                  >
                    Admin
                  </Link>
                </li>
              )}

              <li className="navbar-item">
                <Link
                  className={`navbar-link ${location.pathname.indexOf('explore') !== -1 ? 'active' : ''}`}
                  to={'/explore'}
                  onClick={() => handleLinkClick('/explore')}
                >
                  Explore
                </Link>
              </li>
              {isAdmin ? (
                <></>
              ) : (
                <>
                  <li className="navbar-item">
                    <Link
                      className={`navbar-link ${location.pathname.indexOf('chat') !== -1 ? 'active' : ''}`}
                      to={'/chat'}
                      onClick={() => handleLinkClick('/chat')}
                    >
                      Messages
                    </Link>{' '}
                    {unReadConvoIds.length ? (
                      <span className="badge_count">
                        {unReadConvoIds.length}
                      </span>
                    ) : null}{' '}
                  </li>
                  <li className="navbar-item">
                    <Link
                      className={`navbar-link ${location.pathname.indexOf('mynetwork') !== -1 ? 'active' : ''}`}
                      to={'/mynetwork'}
                      onClick={() => handleLinkClick('/mynetwork')}
                    >
                      My Network
                    </Link>
                  </li>
                </>
              )}
            </ul>
            <ul className="navbar-profile">
              <li className="navbar-item">
                <div className="bagre-bell">
                  <Popover
                    content={notificationcontent}
                    trigger="click"
                    open={open}
                    onOpenChange={onNotificationClick}
                    arrow
                  >
                    <Badge overflowCount={999} size="default">
                      <MenuBellIcon />{' '}
                      {unreadNotificationCount ? (
                        <span className="badge_count">
                          {unreadNotificationCount}
                        </span>
                      ) : (
                        <> </>
                      )}
                    </Badge>
                  </Popover>
                </div>
              </li>
              <li className="navbar-item">
                <Dropdown
                  overlay={profileIconMenu}
                  arrow={{ pointAtCenter: false }}
                  placement="bottom"
                  open={openDropdown}
                >
                  <Space
                    onClick={(e) => {
                      setOpenDropdown(!openDropdown);
                    }}
                  >
                    {!photoURL ? (
                      <Avatar
                        size={40}
                        icon={<UserOutlined />}
                        className="navbar-img"
                      />
                    ) : (
                      <Avatar
                        size={40}
                        src={<img src={photoURL} alt="avatar" />}
                        className="navbar-img"
                      />
                    )}
                    <ArrowMenu />
                  </Space>
                </Dropdown>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      {loaded ? <Outlet></Outlet> : <></>}

      <footer className="footer">
        <div className="footer-left">
          <div className="footer-logo">
            <img src={FOOTER_LOGO} alt="" />
          </div>
          <div className="footer-links">
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.wealthvp.com/privacy-statement"
            >
              Privacy Policy
            </a>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://cdn.prod.website-files.com/6639818d2d30ae6a87243cce/665758f0e4e15df709a1fb76_WealthVP%20Terms%20of%20Use.pdf"
            >
              Terms of Use
            </a>
          </div>
        </div>
        <div className="footer-right">
          <div className="footer-menu">
            <a href="#!">Companies</a>
            <a href="#!">Investors</a>
            <a href="#!">Real Estate</a>
          </div>
          <div className="footer-contact">
            <a
              rel="noreferrer"
              target="_blank"
              href="https://share.hsforms.com/1DlJ6huT7QYuyIc7mrKq2Vw4303u"
            >
              {' '}
              Contact Us
            </a>
            <div className="footer_social_link">
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.linkedin.com/company/wealthvp/"
                className="linkedin"
              >
                <InstagramIcon />
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://x.com/WealthVP"
                className="x"
              >
                <XIcon />
              </a>
            </div>
          </div>
        </div>
      </footer>

      {bottomNavHidden ? (
        ' '
      ) : (
        <>
          <div className="m-menu">
            <footer className="m-menu_item">
              <li
                className={activemenu === 'explore' ? 'active' : ''}
                onClick={() => {
                  navigateTo('/explore');
                  setActivemenu('explore');
                }}
              >
                <ExploreIcon className="meun_icon" />
                <div className="menu_title">Explore</div>
              </li>
              <li
                className={activemenu === 'mynetwork' ? 'active' : ''}
                onClick={() => {
                  navigateTo('/mynetwork');
                  setActivemenu('mynetwork');
                }}
              >
                <NetworkIcon className="meun_icon" />
                <div className="menu_title">Network</div>
              </li>
              <li
                className={activemenu === 'chats' ? 'active' : ''}
                onClick={() => {
                  navigateTo('/chat');
                  setActivemenu('chats');
                }}
              >
                {' '}
                {unReadConvoIds.length ? (
                  <span className="badge_count">{unReadConvoIds.length}</span>
                ) : (
                  <></>
                )}
                <MessagesIcon className="meun_icon" />
                <div className="menu_title">Messages</div>
              </li>
              <li
                className={activemenu === 'profile' ? 'active' : ''}
                onClick={() => {
                  navigateTo('/profile');
                  setActivemenu('profile');
                }}
              >
                <AccountIcon className="meun_icon" />
                <div className="menu_title">Account</div>
              </li>
            </footer>
          </div>
        </>
      )}

      {!showNotification ? (
        <> </>
      ) : (
        <div className="right-side-popup">
          <div className="header">
            <h3>Notifications</h3>
            <button
              onClick={() => setShowNotification(false)}
              className="close-btn"
            >
              &times;
            </button>
          </div>

          <div className="notifications-content slim_scrollbar">
            <NotificationsList notifications={notifications} />
          </div>
        </div>
      )}

      {isWebView || profile == null ? (
        <></>
      ) : (
        <DownloadPrompt profile={profile}></DownloadPrompt>
      )}
    </div>
  );
}
