import * as React from 'react';
import { Row, Col, Input, Form, Button, Avatar } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useProfileStore from '../../data/appState/profileStore';
import { updateProfile } from '../../data/services/profile/profileService';
import { ShimmerSocialPost } from 'react-shimmer-effects';
import FileUploader from '../common/FileUploader';
import { SocialLinksEditor } from '../components/social-links-form-item';
import toast from '../common/Toast';
import { BackArrow } from '../common/utilComponents';

export interface IProfileEditProps {}

export function ProfileEdit(props: IProfileEditProps) {
  const navigateTo = useNavigate();
  const [form] = Form.useForm();

  const { profile, setProfile } = useProfileStore();

  const [pictureUrl, setPictureUrl] = useState<string | null>(
    profile?.photoURL ?? '',
  );
  const [socialLinks, setSocialLinks] = useState(profile?.socialLinks ?? []);

  const [isUpdating, setIsUpdating] = useState(false);

  const onFinish = async (values: any) => {
    if (!pictureUrl) {
      toast.error('Please upload profile picture', '');
      return;
    }
    setIsUpdating(true);
    values['socialLinks'] = socialLinks.length !== 0 ? [...socialLinks] : [];
    values['photoURL'] = pictureUrl ? pictureUrl : '';

    const profileRes = { ...profile, ...values };
    try {
      await updateProfile(profileRes).then((_) => {
        toast.success('Your profile is updated', '');

        setProfile(profileRes);
      });
    } catch (error) {
      console.error(error);
      toast.warning('Failed to save', '');
    } finally {
      setIsUpdating(false);
    }
    navigateTo(-1);
  };
  useEffect(() => {
    if (profile) form.setFieldsValue(profile);
  }, [profile, form]);

  if (!profile) return <ShimmerSocialPost type="both" />;

  return (
    <div className="body_layout">
      <Row>
        <Col span={24}>
          <div className="profile-info">
            <div>
              <Form
                form={form}
                name="profile-edit-form"
                onFinish={onFinish}
                className="grid-3"
                layout="vertical"
              >
                <div className="sub_header">
                  <div className="back_icon">
                    <BackArrow onClick={() => navigateTo(-1)} />

                    <h3>Edit Profile</h3>
                  </div>

                  <div className="sub_header_actions">
                    <>
                      {/* <FileUploader
                        onUploadComplete={(e) => {
                          setPictureUrl(e?.url || null);
                        }}
                      ></FileUploader> */}
                    </>

                    <div>
                      {/* {pictureUrl ? (
                        <Popover
                          placement="bottom"
                          content={
                            <div>
                              <Space direction="vertical">
                                <div
                                  className="color_white hand"
                                  onClick={() => {
                                    setPictureUrl(null);
                                    setIsLogoMenuOpen(false);
                                  }}
                                >
                                  Change
                                </div>
                                <div
                                  className="color_white hand"
                                  onClick={() => {
                                    setPictureUrl(null);
                                    setIsLogoMenuOpen(false);
                                  }}
                                >
                                  Remove
                                </div>
                              </Space>
                            </div>
                          }
                          open={isLogoMenuOpen}
                          onOpenChange={(newOpen: boolean) => {
                            setIsLogoMenuOpen(newOpen);
                          }}
                        >
                          <Avatar
                            shape="circle"
                            alt="Image"
                            icon={<img src={pictureUrl} alt="" />}
                          />
                        </Popover>
                      ) : ( */}
                      {pictureUrl ? (
                        <Avatar
                          shape="circle"
                          alt="Image"
                          icon={<img src={pictureUrl} alt="" />}
                        />
                      ) : (
                        <></>
                      )}
                      <div className="upload-container">
                        <label className="upload-box">
                          <FileUploader
                            onUploadComplete={(e) => {
                              setPictureUrl(e?.url || null);
                            }}
                          ></FileUploader>
                        </label>
                      </div>

                      {/* )} */}
                    </div>
                  </div>
                </div>

                <div className="wvp-p-flex-d">
                  <h3 className="Secondary-textcolor">
                    Profile <span>Information</span>
                  </h3>

                  <div className="grid-3">
                    <Form.Item
                      name="firstName"
                      label="First Name"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your first name!',
                        },
                      ]}
                    >
                      <Input placeholder="First name" />
                    </Form.Item>

                    <Form.Item
                      name="lastName"
                      label="Last Name"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your last name!',
                        },
                      ]}
                    >
                      <Input placeholder="Last name" />
                    </Form.Item>
                    <Form.Item
                      name="displayName"
                      label="DisplayName"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter display name',
                        },
                      ]}
                    >
                      <Input placeholder="display name" />
                    </Form.Item>
                    <Form.Item
                      name="title"
                      label="Job Title"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter Title',
                        },
                      ]}
                    >
                      <Input placeholder="Job title" />
                    </Form.Item>
                    <Form.Item name="phoneNumber" label="Phone Number">
                      <Input placeholder="Enter Phone" type="number" />
                    </Form.Item>
                    <Form.Item
                      name="email"
                      label="Email"
                      rules={[
                        {
                          required: true,
                          type: 'email',
                          message: 'Please enter valid Email',
                        },
                      ]}
                    >
                      <Input placeholder="Enter Email" disabled />
                    </Form.Item>
                    <Form.Item
                      name="city"
                      label="City"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter city',
                        },
                      ]}
                    >
                      <Input placeholder="Enter City" name="city" />
                    </Form.Item>

                    <Form.Item
                      name="state"
                      label="State"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter state',
                        },
                      ]}
                    >
                      <Input placeholder="Enter State" />
                    </Form.Item>

                    <Form.Item
                      name="country"
                      label="Country"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter country',
                        },
                      ]}
                    >
                      <Input placeholder="Enter country" />
                    </Form.Item>
                  </div>

                  <h3 className="Secondary-textcolor">
                    Social <span>Links</span>
                  </h3>
                  <SocialLinksEditor
                    value={socialLinks}
                    onChange={(v) => {
                      setSocialLinks(v);
                    }}
                  />
                  <div className="actions_btn">
                    <Form.Item>
                      {/* <Button
                          loading={isUpdating}
                          type="default"
                          htmlType="submit"
                          className="bg-primary"
                          
                        >
                          Cancel
                        </Button> */}
                      <Button
                        loading={isUpdating}
                        type="primary"
                        htmlType="submit"
                        className="bg-primary"
                      >
                        Save
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
