import * as React from 'react';
import { getInvites } from '../../../data/services/Invites/InviteService';
// ../../data/services/Invites/InviteService
import { Invite } from '../../../domain/types/Invite';
import { Button, Tooltip } from 'antd';
// import { create } from 'domain';
import useProfileStore from '../../../data/appState/profileStore';
import { convertTime } from '../../../data/services/explore/converters';
import { copyToClipboard } from '../../common/utils';

export interface IInviteesProps {}

export function Invitees(props: IInviteesProps) {
  const [invites, setInvites] = React.useState<Invite[]>([]);

  const [email, setEmail] = React.useState<string>('');

  const { profile } = useProfileStore();

  const loadInvites = React.useCallback(() => {
    if (profile && profile.uid)
      getInvites().then((invites) => {
        invites = invites
          .map((invite) => {
            const { updatedOn } = invite as any;
            if (updatedOn?.toDate()) invite.updatedOn = updatedOn?.toDate();
            return invite;
          })
          .sort((a, b) => {
            return b.updatedOn.getTime() - a.updatedOn.getTime();
          });
        console.log(invites);
        setInvites(invites);
      });
  }, [profile]);

  React.useEffect(() => {
    loadInvites();
  }, [loadInvites]);

  return (
    <div className="body_layout">
      <div className="user_invite">
        <div className="invite_form">
          <div className="title_wrapper">
            <h2>User Invites</h2>
          </div>
          <div className="form_wrapper">
            <h2> Send invite </h2>
            <div className="form_input">
              <input
                type="text"
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                placeholder="Email"
              />
              {/* <Button type="primary" onClick={sendInvite}>
                Send
              </Button> */}
            </div>
          </div>
        </div>
      </div>

      {invites && invites.length !== 0 ? (
        <div className="table-container slim_scrollbar invite_table ">
          <table>
            <thead>
              <tr>
                <th>Email</th>
                <th>Invited On</th>
                <th>Created By</th>
                {/* <th>Invited On</th> */}
                <th>STATUS</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {invites.map((invite) => {
                return (
                  <tr key={invite.db_ref_id}>
                    <td>{invite.createToEmail}</td>
                    <td>{convertTime(invite?.updatedOn)}</td>
                    <td>{invite.createdByName}</td>
                    <td>
                      {invite.status !== 'accepted' ? (
                        invite.status
                      ) : (
                        <div style={{ color: 'green' }}>
                          <Tooltip
                            placement="bottomLeft"
                            title={
                              <span style={{ color: 'orange' }}>
                                {'Accepted By ' +
                                  invite.acceptedByName +
                                  '(' +
                                  invite.acceptedByEmail +
                                  ') for the organisation ' +
                                  invite.acceptedByOrgName}
                              </span>
                            }
                            arrow={true}
                          >
                            <p style={{ color: 'green' }}>Accepted</p>
                          </Tooltip>
                        </div>
                      )}
                    </td>

                    <td>
                      {' '}
                      <Button
                        type="primary"
                        onClick={() => {
                          copyToClipboard(
                            window.location.origin +
                              '/invitee/signup/' +
                              invite.db_ref_id,
                          );
                        }}
                      >
                        Copy Link
                      </Button>{' '}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <p style={{ textAlign: 'center' }}>
          {' '}
          <b>No invitees</b>
        </p>
      )}
    </div>
  );
}
