/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line jsx-a11y/anchor-is-valid
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useProfileStore from '../../data/appState/profileStore';
import { ShimmerSocialPost } from 'react-shimmer-effects';
import { Avatar, Button, Form, Input, Select } from 'antd';
import { Company, TeamMember } from '../../domain/types/Company';
import { useEffect, useState } from 'react';

import FileUploader from '../common/FileUploader';
import { DEFAULT_FIRM_IMG } from '../common/constants';
import { realestateSiteProperties } from '../../data/services/common/siteDataService';
import {
  getReSponsorById,
  updateResponsor,
} from '../../data/services/realEstate/realEstateService';
import { RealEstateSponsor } from '../../domain/types/RealEstate';
import { SocialLinksEditor } from '../components/social-links-form-item';
import toast from '../common/Toast';
import { BackArrow } from '../common/utilComponents';
import { TeamMemberForm } from '../components/TeamMemberForm';
import { TeamMembersList } from '../components/TeamMembersList';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export interface IRealEstateEditProps {}

export default function RealEstateEdit(props: IRealEstateEditProps) {
  const { assets_under_management, property_sector } =
    realestateSiteProperties ?? {};

  const { id: sponsorId } = useParams();
  const { profile } = useProfileStore();
  const navigateTo = useNavigate();

  const [realEstateSponsor, setREsponsor] =
    React.useState<RealEstateSponsor | null>(null);

  useEffect(() => {
    if (sponsorId)
      getReSponsorById(sponsorId)
        .then((data: RealEstateSponsor) => {
          setREsponsor(data);
          if (data.teamMembers?.length) {
            setTeamMembers(data.teamMembers);
          }
          if (data.socialLinks?.length) {
            setSocialLinks(data.socialLinks);
          }
          if (data.logoUri?.length) {
            setPictureUrl(data.logoUri);
          }
        })
        .catch((err) => {
          console.log(err);
        });
  }, [sponsorId]);

  const [form] = Form.useForm<RealEstateSponsor>();

  const [pictureUrl, setPictureUrl] = useState<any>(
    realEstateSponsor?.logoUri ?? '',
  );
  const [socialLinks, setSocialLinks] = useState<string[]>(
    realEstateSponsor?.socialLinks ?? [],
  );
  //const [statusValue, setStatusValue] = useState<boolean>();
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>(
    realEstateSponsor?.teamMembers ?? [],
  );
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  if (!profile || !realEstateSponsor) return <ShimmerSocialPost type="both" />;

  const onFinish = async (values: Company) => {
    if (!pictureUrl) {
      toast.error('Please upload profile picture', '');
      return;
    }

    setIsUpdating(true);
    values.teamMembers = teamMembers;
    values['socialLinks'] = socialLinks.length !== 0 ? [...socialLinks] : [];
    values['logoUri'] = pictureUrl ? pictureUrl : null;
    var updatedResponsor = { ...realEstateSponsor, ...values };

    try {
      await updateResponsor(updatedResponsor);
      toast.success('Your profile is updated', '');
      // navigateTo('/company/' + company.id);
      navigateTo(-1);
    } catch (error) {
      console.warn(error);
      toast.warning('Failed to save', '');
    } finally {
      setTimeout(() => {
        setIsUpdating(false);
      }, 600);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="body_layout">
      <Form
        initialValues={realEstateSponsor}
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="sub_header">
          <div className="back_icon">
            <BackArrow onClick={() => navigateTo(-1)} />
            <h3>Edit Real Estate Profile</h3>
          </div>

          {/* {JSON.stringify(pictureUrl)} */}

          <div className="sub_header_actions">
            {/* {pictureUrl ? (
              <Popover
                placement="bottom"
                content={
                  <div>
                    <Space direction="vertical">
                      <a
                        onClick={() => {
                          setPictureUrl(null);
                          setOpenLogoMenu(false);
                        }}
                      >
                        Change
                      </a>
                      <a
                        onClick={() => {
                          setPictureUrl(null);
                          setOpenLogoMenu(false);
                        }}
                      >
                        Remove
                      </a>
                    </Space>
                  </div>
                }
                open={openLogoMenu}
                onOpenChange={(newOpen: boolean) => {
                  setOpenLogoMenu(newOpen);
                }}
              >
                <Avatar
                  shape="square"
                  size={54}
                  icon={
                    <img
                      alt=""
                      src={pictureUrl ? pictureUrl : DEFAULT_FIRM_IMG}
                    />
                  }
                />
              </Popover>
            ) : ( */}
            <Avatar
              shape="square"
              icon={
                <img alt="" src={pictureUrl ? pictureUrl : DEFAULT_FIRM_IMG} />
              }
            />
            <div className="upload-container">
              <label className="upload-box">
                <FileUploader
                  onUploadComplete={(e) => {
                    setPictureUrl(e?.url);
                  }}
                ></FileUploader>
              </label>
            </div>
            {/* )} */}
          </div>
        </div>
        <div className="wvp-p-flex-d">
          <h3 className="Secondary-textcolor">Real Estate</h3>{' '}
          <div className="grid-3 ">
            <Form.Item
              name="name"
              label="Real Estate Name"
              rules={[
                {
                  required: true,
                  message: 'Please input Real Estate Name',
                },
              ]}
            >
              <Input placeholder="Real Estate Name" />
            </Form.Item>
            <Form.Item
              name="assetsUnderManagement"
              label="Assets Under Management"
              rules={[
                {
                  required: true,
                  message: 'Please enter Assets Under Management',
                },
              ]}
            >
              <Select
                onChange={(e: any) => {
                  debugger;
                  // setStatusValue(e);
                }}
                options={assets_under_management?.options ?? []}
              />
            </Form.Item>
            <Form.Item
              name="numberOfDealsCompleted"
              label="Number of Deals Completed"
              rules={[
                {
                  required: true,
                  message: 'Please enter Number of Deals Completed',
                },
              ]}
            >
              <Input type="number" placeholder=" Number of Deals Completed" />
            </Form.Item>

            <Form.Item
              name="yearsInIndustry"
              label="Years in Industry"
              rules={[
                {
                  required: true,
                  message: 'Please enter your Years in Industry',
                },
              ]}
            >
              <Input type="number" placeholder="Years in Industry" />
            </Form.Item>
          </div>
          <h3 className="Secondary-textcolor">Sectors you have had deals in</h3>
          <div>
            <Form.Item
              name="sectors"
              rules={[
                {
                  required: true,
                  message: 'Please select sectores',
                },
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Please select"
                // defaultValue={industries?.length ? [...industries] : []}
                options={property_sector?.options ?? []}
              />
            </Form.Item>
          </div>
          <h3 className="Secondary-textcolor">Firm</h3>
          <div className="grid-3">
            <Form.Item
              name="city"
              label="City"
              rules={[
                {
                  required: true,
                  message: 'Please enter valid location',
                },
              ]}
            >
              <Input placeholder="Enter City" name="city" />
            </Form.Item>

            <Form.Item
              name="state"
              label="State"
              rules={[
                {
                  required: true,
                  message: 'Please enter valid legal name',
                },
              ]}
            >
              <Input placeholder="Enter State" />
            </Form.Item>

            <Form.Item
              name="country"
              label="Country"
              rules={[
                {
                  required: true,
                  message: 'Please enter country',
                },
              ]}
            >
              <Input placeholder="Enter country" />
            </Form.Item>

            <Form.Item
              name="websiteUri"
              label="Website"
              rules={[
                {
                  required: true,

                  message: 'Please enter valid website',
                },
                { type: 'url' },
                { type: 'string', min: 6 },
              ]}
            >
              <Input placeholder="Enter website" />
              {/* <Input placeholder="Enter website" /> */}
            </Form.Item>
          </div>
          <h3 className="Secondary-textcolor">About</h3>
          <Form.Item
            name="about"
            rules={[{ required: true, message: 'Please input Intro' }]}
          >
            {/* <Input.TextArea rows={10} /> */}
            <ReactQuill theme="snow" />
          </Form.Item>
          <h3 className="Secondary-textcolor">Team</h3>
          <TeamMemberForm
            onAddMember={(teamMember) => {
              setTeamMembers([...teamMembers, teamMember]);
            }}
          ></TeamMemberForm>
          <div>
            <TeamMembersList
              teamMembers={teamMembers}
              onDeleteMember={(index) => {
                teamMembers.splice(index, 1);
                setTeamMembers?.([...teamMembers]);
              }}
            ></TeamMembersList>
          </div>
          <h3 className="Secondary-textcolor">
            Social <span>Links</span>{' '}
          </h3>
          <SocialLinksEditor
            value={socialLinks}
            onChange={(v) => {
              setSocialLinks(v);
            }}
          />
          <div className="actions_btn">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="bg-primary"
                //   style={{ float: 'right', gridColumn: '3/4' }}pr
                loading={isUpdating}
              >
                Save
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
}
